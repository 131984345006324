import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import logoImg from '../../images/logo.png';
import styles from './Header.module.css';

export const Header = ({ siteTitle }) => (
  <header className={styles.header}>
    <div className="row-14">
      <Link to="/" className={styles.logoContainer}>
        <img src={logoImg} alt="Alona Yunda" />
      </Link>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
