import React from 'react';
import { Link } from 'gatsby';

import footerLogoSrc from '../../images/footer-logo.png';
import styles from './Footer.module.css';

export const Footer = ({ children }) => (
  <footer className={styles.footer}>
    <div className="container">
      {children}
      <div className={styles.contacts}>
        <div className={styles.logo}>
          <img src={footerLogoSrc} alt="Alona Yunda" />
        </div>
        <div className={styles.links}>
          <ul className={styles.linksList}>
            <li>
              <a href="https://firebasestorage.googleapis.com/v0/b/alona-yunda.appspot.com/o/CV_Alona_Yunda.pdf?alt=media">CV</a>
            </li>
            <li>
              <a href="https://twitter.com/alonayunda">Twitter</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/alona-yunda-68b5344b/">LinkedIn</a>
            </li>
            <li>
              <a href="mailto:aldona.green@gmail.com">aldona.green@gmail.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);
