import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { Header } from '../Header';
import { Footer } from '../Footer';
import './reset.css';
import './global.css';

import styles from './Layout.module.css';

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const { content, footer } = children.reduce((result, current) => {
    if (React.isValidElement(current) && current.type === Footer) {
      result.footer = current;
    } else {
      result.content.push(current);
    }
    return result;
  }, { content: [], footer: null });

  return (
    <section className={styles.layout}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={styles.content}>{content}</main>
      {footer}
    </section>
  );
};
